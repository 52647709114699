import React from 'react';
import * as styles from './styles.module.scss';

const WistiaVideo = ({ videoLink }) => {
  if (!videoLink) return null;
  return (
    <div className={styles.wistiaVideo}>
      <div className={styles.wistiaVideoResponsiveWrapper}>
        <iframe
          src={videoLink}
          title="Wistia Video"
          allowtransparency="true"
          frameBorder="0"
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          allowFullScreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default WistiaVideo;
