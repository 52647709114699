// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--d2156";
export var animateScrollOut = "styles-module--animateScrollOut--2b9cf";
export var colorSequence = "styles-module--colorSequence--df67d";
export var copyright = "styles-module--copyright--8a8f0";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--52b46";
export var footerBottom = "styles-module--footerBottom--62488";
export var grow = "styles-module--grow--89d8c";
export var growAndShrink = "styles-module--growAndShrink--104dd";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--7b03c";
export var links = "styles-module--links--84ea0";
export var moveBg = "styles-module--move-bg--fd3e4";
export var rotateLoop = "styles-module--rotateLoop--08c04";
export var spin = "styles-module--spin--2ff0c";
export var spinCounter = "styles-module--spinCounter--7280d";