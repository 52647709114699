// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--1b226";
export var animateScrollOut = "styles-module--animateScrollOut--28063";
export var colorSequence = "styles-module--colorSequence--9e34a";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--db3ac";
export var grow = "styles-module--grow--18b72";
export var growAndShrink = "styles-module--growAndShrink--02cce";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--02b61";
export var moveBg = "styles-module--move-bg--733dd";
export var rotateLoop = "styles-module--rotateLoop--54b25";
export var spin = "styles-module--spin--99100";
export var spinCounter = "styles-module--spinCounter--6673b";
export var white = "styles-module--white--1c02f";