// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--4c07a";
export var animateScrollOut = "styles-module--animateScrollOut--c5c43";
export var colorSequence = "styles-module--colorSequence--454ac";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--6980a";
export var footer = "styles-module--footer--e4089";
export var footerMiddle = "styles-module--footerMiddle--6a2fe";
export var footerNav = "styles-module--footerNav--334e1";
export var footerTagline = "styles-module--footerTagline--e9a39";
export var footerText = "styles-module--footerText--84547";
export var grow = "styles-module--grow--6b08e";
export var growAndShrink = "styles-module--growAndShrink--d5e11";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--201c8";
export var moveBg = "styles-module--move-bg--83dff";
export var rotateLoop = "styles-module--rotateLoop--b22c3";
export var spin = "styles-module--spin--26c9c";
export var spinCounter = "styles-module--spinCounter--3b792";