import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const FooterBottom = ({ data }) => (
  <div className={styles.footerBottom}>
    <div className={styles.copyright}>&copy;{new Date().getFullYear()} Catamorphic Co.</div>
    <ul className={styles.links}>
      {data.map((snsLink) => {
        const { link, link_text: linkText, social_icon: socialIcon } = snsLink;

        return (
          <li key={linkText.text}>
            <Link to={link.url}>
              <img src={socialIcon.url} alt={linkText.text} />
            </Link>
          </li>
        );
      })}
    </ul>
  </div>
);

export default FooterBottom;
