import React, { useState } from 'react';
import { Link, Navigation } from 'components';
import * as styles from './styles.module.scss';

const Header = ({ linkItem, resourceLinkItem }) => {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const handleBurgerClick = (e) => {
    e.preventDefault();
    setBurgerOpen(!burgerOpen);
  };

  const burgerOpenClass = burgerOpen ? styles.burgerOpen : '';
  const mobileMenuOpenClass = burgerOpen ? styles.mobileMenuOpen : '';

  return (
    <header className={styles.header}>
      <div className={styles.navLeft}>
        <Link className={styles.logo} to="/">
          <img
            src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/04f2381d-7732-42f2-a546-4e370b4f8547_launchdarkly.svg"
            alt="LaunchDarkly Logo"
          />
          <span>Developers</span>
        </Link>

        <div
          role="button"
          tabIndex="0"
          className={`${styles.mobileBurger} ${burgerOpenClass}`}
          data-target="headerNav"
          onClick={handleBurgerClick}
          aria-label="Opens mobile menu"
        >
          <span />
          <span />
        </div>
      </div>
      <div className={`${styles.navRight} ${mobileMenuOpenClass}`}>
        <Navigation linkItem={linkItem} resourceLinkItem={resourceLinkItem} />
        <div className={styles.signInGroup}>
          <Link
            className={styles.signInButton}
            to="https://app.launchdarkly.com/"
            persistExternalQueryParams
          >
            <span>Sign In</span>
          </Link>
          <Link
            id="main-nav-request-demo"
            className={`button ${styles.demoButton}`}
            to="https://app.launchdarkly.com/signup?ref=devhub"
            eventName="Request Demo Button Clicked"
          >
            Start trial
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
