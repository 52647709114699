// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--bd23e";
export var animateScrollOut = "styles-module--animateScrollOut--89275";
export var colorSequence = "styles-module--colorSequence--d148e";
export var container = "styles-module--container--fb316";
export var content = "styles-module--content--02665";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--c5c62";
export var grow = "styles-module--grow--717f4";
export var growAndShrink = "styles-module--growAndShrink--ae433";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--062f9";
export var imageContainer = "styles-module--imageContainer--ec7d6";
export var moveBg = "styles-module--move-bg--793b5";
export var rotateLoop = "styles-module--rotateLoop--63705";
export var section = "styles-module--section--7a6d2";
export var spin = "styles-module--spin--4e8ff";
export var spinCounter = "styles-module--spinCounter--c9f7a";
export var texts = "styles-module--texts--41f35";
export var title = "styles-module--title--7cfcc";