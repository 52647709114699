import React from 'react';
import * as styles from './styles.module.scss';

const HomepageHero = ({ title, subtitle }) => (
  <section className={styles.section}>
    <div className={styles.container}>
      <div className={styles.texts}>
        <h1 className={`gradient-text ${styles.title}`}>{title.text}</h1>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: subtitle.html }} />
      </div>
    </div>
    <div className={styles.imageContainer}>
      <img
        src="https://images.prismic.io/developers-launchdarkly/b3fbcab2-d78b-4885-9077-b3f60f5c11b5_developers-hero-updated.png?auto=compress,format"
        alt="Homepage Hero Image"
      />
      {/* <div className={styles.bgImage} /> */}
    </div>
  </section>
);

export default HomepageHero;
