import React from 'react';
import * as styles from './styles.module.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = (props) => {
  const { className, containerClassName, children, jumpId, id, sliceName, whiteBg } = props;
  return (
    <section
      id={id}
      data-jump-id={jumpId}
      className={`section ${className || ''} ${whiteBg ? styles.white : ''}`}
      data-slice={isDev ? sliceName : null}
    >
      <div className={`container ${containerClassName || ''}`}>{children}</div>
    </section>
  );
};

export default Section;
