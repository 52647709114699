import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const DocumentationNav = ({ nav }) => {
  const navCategory = [
    'Feature Flags',
    'Platform Configuration',
    'Accelerate',
    'Experimentation',
    'Additional Features',
  ];

  const showIcon = (iconCat) => {
    let iconSrc = '';
    switch (iconCat) {
      case 'Feature Flags':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/8e08138b-3e50-4c9b-ad22-2a93c4a8094c_menu-icon-feature-flags.svg';
        break;

      case 'Platform Configuration':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/8025e2d7-6112-4803-8721-89d2c0ffa41b_menu-icon-platform-config.svg';
        break;

      case 'Accelerate':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/145344d9-4e3a-498f-9509-caf95b712474_menu-icon-accelerate.svg';
        break;

      case 'Experimentation':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/6d028bb7-4a1a-4755-b7f6-7b05e5f3fd1b_menu-icon-experimentation.svg';
        break;

      case 'Additional Features':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/3ee0b0f1-245c-4d1e-a869-c1cf389c8ed4_menu-icon-additional-features.svg';
        break;
    }

    return iconSrc;
  };

  return (
    <div>
      <div className={styles.categoryNavGroup}>
        <div>
          <div className={styles.whiteBox}>
            <h5>Get technical.</h5>
            <Link to="https://docs.launchdarkly.com/home" withArrow>
              See all documentation
            </Link>
            <img
              className={styles.whiteBoxIcon}
              src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/3249065c-1058-4b98-a398-989298aa1a88_menu-icon-full-doc.svg"
              alt="See all documentation"
            />
          </div>
        </div>

        {navCategory.map((cat) => (
          <div key={cat} className={styles.categoryNav}>
            <h5>
              <span className={styles.icon}>
                <img src={showIcon(cat)} alt={`${cat} icon`} />
              </span>
              {cat}
            </h5>
            <ul>
              {nav.map((linkItem) => {
                const { link, link_text: linkText, nav_category: linkCat } = linkItem;

                if (cat === linkCat) {
                  return (
                    <li key={linkText.text}>
                      <Link to={link.url}>{linkText.text}</Link>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentationNav;
