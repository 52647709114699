// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--440c0";
export var animateScrollOut = "styles-module--animateScrollOut--e7a5e";
export var bottomLeft = "styles-module--bottomLeft--30063";
export var bottomRight = "styles-module--bottomRight--d3680";
export var center = "styles-module--center--c6305";
export var colorSequence = "styles-module--colorSequence--4c4c1";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--992de";
export var float = "styles-module--float--90251";
export var floatingAcademy = "styles-module--floatingAcademy--4850f";
export var grow = "styles-module--grow--08ee1";
export var growAndShrink = "styles-module--growAndShrink--1a9be";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--caa76";
export var moveBg = "styles-module--move-bg--d7ba2";
export var rotateLoop = "styles-module--rotateLoop--90b0a";
export var sectionVisible = "styles-module--sectionVisible--77bc1";
export var spin = "styles-module--spin--2c9a2";
export var spinCounter = "styles-module--spinCounter--ae9e5";
export var topLeft = "styles-module--topLeft--b7ef4";
export var topRight = "styles-module--topRight--b196f";