import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const ResourcesNav = ({ nav }) => {
  const showIcon = (iconCat) => {
    let iconSrc = '';
    switch (iconCat) {
      case 'API Reference':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/86bf8d24-1537-42cd-a529-1b3b926a21c0_menu-icon-api-ref.svg';
        break;

      case 'Docs':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/551a9d3f-ebd8-4802-92fb-80b90a344a35_menu-icon-docs.svg';
        break;

      case 'Community':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/6df28ace-7bc8-4baf-9979-f98519a5f520_menu-icon-community.svg';
        break;

      case 'Interactive demos':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/c00229b5-5c67-4137-ac45-bcc423609be8_menu-icon-interactive-demo.svg';
        break;

      case 'SDK':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/e87c6527-d682-4e41-8821-0caaccbbcd4c_menu-icon-sdk.svg';
        break;
      case 'Integrations':
        iconSrc =
          'https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/9f4f1dbb-ea17-43ef-a409-4666830843f3_menu-icon-integrations.svg';
        break;
    }

    return iconSrc;
  };

  return (
    <div className={styles.resourceLinks}>
      {nav.map((navItem) => {
        const { link, link_description: linkDescription, link_icon: linkIcon, link_text: linkText } = navItem;

        return (
          <Link key={linkText.text} className={styles.link} to={link.url}>
            <h5>
              <span className={styles.icon}>
                <img src={showIcon(linkIcon)} alt={`${linkText.text} icon`} />
              </span>
              {linkText.text}
            </h5>
            <div className={styles.descContainer} dangerouslySetInnerHTML={{ __html: linkDescription.html }} />
          </Link>
        );
      })}
    </div>
  );
};

export default ResourcesNav;
