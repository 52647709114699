import React from 'react';
import { OnVisible } from 'components';
import * as styles from './styles.module.scss';

const FloatingAcademy = () => (
  <OnVisible
    className={styles.floatingAcademy}
    visibleClassName={styles.sectionVisible}
    threshold={1}
  >
    <img
      className={styles.topLeft}
      src="https://images.prismic.io/developers-launchdarkly/f0f08e66-ddf3-42ea-8198-79e2f6ab6f7d_academy-top-left.png?auto=compress,format"
      alt="academy banner top left"
    />
    <img
      className={styles.topRight}
      src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/20e9cd1e-4729-45c8-a24b-bbe65f3b39b4_academy-top-right.svg"
      alt="academy banner top right"
    />
    <img
      className={styles.bottomLeft}
      src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/85e937c6-2da0-4cb9-8888-3f4a349dacef_academy-bottom-left.svg"
      alt="academy banner bottom left"
    />
    <img
      className={styles.bottomRight}
      src="https://images.prismic.io/developers-launchdarkly/30d92dea-7952-4002-8f53-2e8f65d6c8fc_academy-bottom-right.png?auto=compress,format"
      alt="academy banner bottom right"
    />
    <img
      className={styles.center}
      src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/c0cb028c-725b-402f-8265-7635d48ca274_academy-center.svg"
      alt="academy banner center"
    />
  </OnVisible>
);

export default FloatingAcademy;
