import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const GradientCard = (props) => {
  const { children, linkTo, cardClass } = props;

  return (
    <>
      {linkTo ? (
        <Link className={`${styles.gradientCard} ${cardClass}`} to={linkTo}>
          {children}
        </Link>
      ) : (
        <div className={`${styles.gradientCard} ${cardClass}`}>{children}</div>
      )}
    </>
  );
};

export default GradientCard;
