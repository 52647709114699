// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--d9e1e";
export var animateScrollOut = "styles-module--animateScrollOut--ed57a";
export var cardContainer = "styles-module--cardContainer--b769c";
export var colorSequence = "styles-module--colorSequence--92a34";
export var desc = "styles-module--desc--a0b6a";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--c6676";
export var grow = "styles-module--grow--76bd2";
export var growAndShrink = "styles-module--growAndShrink--db379";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--f45bd";
export var moveBg = "styles-module--move-bg--28863";
export var rotateLoop = "styles-module--rotateLoop--b1fe2";
export var section = "styles-module--section--0e9df";
export var spin = "styles-module--spin--288c9";
export var spinCounter = "styles-module--spinCounter--8093e";
export var textContainer = "styles-module--textContainer--db5a8";