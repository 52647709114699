import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Section, Slice, HomepageHero } from 'components';
import MultiLinkBlocks from '../slices/MultiLinkBlocks';
import * as home from './home.module.scss';

const Home = ({ data }) => {
  const {
    home: {
      data: { title, subtitle, body: sliceData },
    },
  } = data;

  const multiLinkBlockData = [];

  return (
    <Layout>
      <HomepageHero title={title} subtitle={subtitle} />
      {sliceData.map((slice) => {
        if (slice.slice_type === 'multi_link_blocks') {
          multiLinkBlockData.push(slice);
        } else {
          if (slice.slice_type === 'connect_banner') {
            return (
              <React.Fragment key={slice.id}>
                <Slice key={slice.id} data={slice} />
                <MultiLinkBlocks data={multiLinkBlockData} />
              </React.Fragment>
            );
          }
          return <Slice key={slice.id} data={slice} />;
        }
      })}
    </Layout>
  );
};

export default Home;
export { Head } from '../components/Common/SEO/Head';

export const pageQuery = graphql`
  query HomeQuery {
    home: prismicHome {
      id
      prismicId
      _previewable
      data {
        use_as_homepage
        title {
          text
        }
        subtitle {
          text
          html
        }
        schema {
          text
        }
        open_graph_image {
          url
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        body {
          ...homeLinkBlocksWithIcon
          ...HomeConnectBanner
          ...homeLinkBlocksWithImage
          ...HomeMultiLinkBlocks
          ...HomeLaunguageIntegrationBlock
          ...HomeBannerWithLargeImage
          ...HomeVideoBlocks
          ...HomeUpcomingEvents
        }
      }
    }
  }
`;
