import React, { useState } from 'react';
import { Image, WistiaVideo, Modal } from 'components';
import * as styles from './styles.module.scss';

const VideoBlockItem = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const {
    video_title: videoTitle,
    video_description: videoDescription,
    video_length: videoLength,
    wistia_video_id: wistiaId,
    video_preview_image: previewImage,
  } = data;

  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  return (
    <>
      <a className={styles.video} href="#" onClick={openModal}>
        <div className={styles.imageContainer}>
          <img
            className={styles.playVideoIcon}
            src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/f2bbd869-4d55-4f01-bf64-d9d3ca181f7a_icon-play-video.svg"
            alt="Play Video Icon"
          />
          <Image className={styles.previewImage} image={previewImage} />
        </div>
        <div>
          <h6 className={styles.videoTitle}>{videoTitle.text}</h6>
          <p>
            <span className={styles.watch}>
              Watch
              <img
                src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/ac4d263c-9180-4cec-9ae0-984d5227424b_icon-watch-video.svg"
                alt="icon watch"
              />
            </span>
            <span className={styles.videoLength}>{videoLength} min</span>
          </p>
          {videoDescription.text && (
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: videoDescription.html }} />
          )}
        </div>
      </a>
      <Modal active={showModal} closeModal={closeModal} className="iframe-modal">
        <div className="iframe-container">
          <WistiaVideo videoLink={`https://fast.wistia.net/embed/iframe/${wistiaId.text}?autoplay=true`} />
        </div>
      </Modal>
    </>
  );
};

export default VideoBlockItem;
