import React from 'react';
import { Section, LangInteCard } from 'components';
import * as styles from './styles.module.scss';

const IntegrationCarousel = ({ title, desc, links }) => (
  <Section className={styles.section}>
    <div className={styles.textContainer}>
      <h3>{title}</h3>
      <div className={styles.desc} dangerouslySetInnerHTML={{ __html: desc.html }} />
    </div>
    <div className={styles.cardContainer}>
      {links.map((block) => {
        console.log(block);
        const { block_title: blockTitle, link_url: linkUrl, logo } = block;

        return <LangInteCard title={blockTitle.text} url={linkUrl.url} icon={logo.url} />;
      })}
    </div>
  </Section>
);
export default IntegrationCarousel;
