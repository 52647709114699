// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--3bb3e";
export var animateScrollOut = "styles-module--animateScrollOut--4cc26";
export var arrowBody = "styles-module--arrowBody--bf242";
export var arrowBodyAnimation = "styles-module--arrowBodyAnimation--82119";
export var arrowContainer = "styles-module--arrowContainer--33ce8";
export var arrowContainerAnimation = "styles-module--arrowContainerAnimation--e01d6";
export var arrowHead = "styles-module--arrowHead--15e80";
export var colorSequence = "styles-module--colorSequence--87ec8";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--81862";
export var flameBubble = "styles-module--flameBubble--d66c5";
export var grow = "styles-module--grow--43d51";
export var growAndShrink = "styles-module--growAndShrink--4c568";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--f6e7a";
export var moveBg = "styles-module--move-bg--1988b";
export var rotateLoop = "styles-module--rotateLoop--fcbad";
export var slidingArrow = "styles-module--slidingArrow--071f5";
export var spin = "styles-module--spin--49d06";
export var spinCounter = "styles-module--spinCounter--5e7d5";
export var translateHeadAnimation = "styles-module--translateHeadAnimation--d0110";