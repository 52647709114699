// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--d7858";
export var animateScrollOut = "styles-module--animateScrollOut--e8eed";
export var colorSequence = "styles-module--colorSequence--eac31";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--3eefa";
export var grow = "styles-module--grow--29636";
export var growAndShrink = "styles-module--growAndShrink--bdd66";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--b0080";
export var growingHeart = "styles-module--growingHeart--3aaac";
export var heart = "styles-module--heart--e284f";
export var heartAnimation = "styles-module--heartAnimation--5cb2d";
export var moveBg = "styles-module--move-bg--3b2f3";
export var rotateLoop = "styles-module--rotateLoop--edc1f";
export var spin = "styles-module--spin--38940";
export var spinCounter = "styles-module--spinCounter--d723f";