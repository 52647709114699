// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--fc67c";
export var animateScrollOut = "styles-module--animateScrollOut--07686";
export var colorSequence = "styles-module--colorSequence--844d8";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--ccd76";
export var gradientCard = "styles-module--gradientCard--05f07";
export var grow = "styles-module--grow--ca8c7";
export var growAndShrink = "styles-module--growAndShrink--3cb91";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--4554e";
export var moveBg = "styles-module--move-bg--821a8";
export var rotateLoop = "styles-module--rotateLoop--0d5e5";
export var spin = "styles-module--spin--c2aef";
export var spinCounter = "styles-module--spinCounter--95a4a";