// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--8106f";
export var animateScrollOut = "styles-module--animateScrollOut--1606b";
export var colorSequence = "styles-module--colorSequence--24484";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--53e66";
export var grow = "styles-module--grow--6c960";
export var growAndShrink = "styles-module--growAndShrink--2624b";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--7d79b";
export var moveBg = "styles-module--move-bg--3a3de";
export var rotateLoop = "styles-module--rotateLoop--c0901";
export var spin = "styles-module--spin--840b3";
export var spinCounter = "styles-module--spinCounter--0d023";
export var toggleBall = "styles-module--toggleBall--56070";
export var toggleBallAnimation = "styles-module--toggleBallAnimation--00b52";
export var toggleContainer = "styles-module--toggleContainer--85815";