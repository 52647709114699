import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { ReactComponent as Arrow } from 'images/arrow-forward.svg';
// import { track } from '../../../utils/thirdPartyUtils';
import Cookies from 'js-cookie';
import * as styles from './styles.module.scss';

const Link = (props) => {
  const {
    children,
    activeClassName,
    className,
    eventName,
    trackingId,
    to,
    title,
    target = '_blank',
    tabIndex,
    ariaLabel,
    ariaDescribedby,
    withArrow,
    role,
    style,
    persistExternalQueryParams,
    onClick = () => {},
    onMouseOver = () => {},
    onMouseOut = () => {},
    trigger,
    triggerValue,
    id,
  } = props;

  const location = useLocation();
  const cookieDomain = `${location.hostname}`;

  const onLinkClick = () => {
    // if (eventName) {
    //   handleEvent(eventName);
    // }
    // if (trackingId) {
    //   handleCtaTracking(trackingId);
    // }
    if (!eventName && !trackingId) {
      onClick();
    }
  };

  // const handleEvent = (evtName) => {
  //   if (evtName) {
  //     track(evtName, {
  //       url: location?.href,
  //       path: location?.pathname,
  //       cta: children ?? '',
  //     });
  //   }
  // };

  // const handleCtaTracking = (trackingId) => {
  //   Cookies.set('website-dr-button', trackingId, {
  //     domain: cookieDomain,
  //     expires: 30,
  //   });
  // };

  if (!to) {
    if (!trigger)
      return (
        <span className={`${className || ''} ${withArrow ? styles.withArrow : ''}`}>
          {children}
          {withArrow && <Arrow className="cta-arrow" />}
        </span>
      );

    return (
      <span onClick={() => trigger(triggerValue)} className={`${className || ''} ${withArrow ? styles.withArrow : ''}`}>
        {children}
        {withArrow && <Arrow className="cta-arrow" />}
      </span>
    );
  }

  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#') || (to && to.indexOf('mailto') !== -1);
  const launchDarklyUrl = 'https://launchdarkly.com';
  const isLaunchDarklyLink = to && to.indexOf(launchDarklyUrl) !== -1;
  const { search: qString } = location;

  const containsSiteUrl = process.env.GATSBY_SITE_URL && to && to.indexOf(process.env.GATSBY_SITE_URL) !== -1;

  if (isExternal && !containsSiteUrl && !isLaunchDarklyLink) {
    return (
      <a
        href={persistExternalQueryParams ? to + qString : to}
        className={`${className || ''} ${withArrow ? styles.withArrow : ''}`}
        title={title || null}
        aria-label={ariaLabel || null}
        aria-describedby={ariaDescribedby || null}
        target={target}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        rel="nofollow noopener noreferrer"
        role={role}
        style={style}
        id={id}
      >
        {children}
        {withArrow && <Arrow className="cta-arrow" />}
      </a>
    );
  }

  if (!to) {
    return <span className={className}>{children}</span>;
  }

  const relativeTo = isLaunchDarklyLink ? to.replace(launchDarklyUrl, '') : to;
  const linkTo = to;
  const withTrailingSlash = `${linkTo}${linkTo.endsWith('/') ? '' : '/'}`;

  // UTM forwarding logic
  const toQuery = linkTo.substring(to.indexOf('?') + 1);
  const currentLocationQueryParsed = queryString.parse(qString);
  const queryStringified = queryString.stringify(currentLocationQueryParsed);

  let toWithQueries = linkTo;

  if (linkTo.includes('?') && !qString.includes(toQuery)) {
    toWithQueries = `${linkTo}&${queryStringified}`;
  } else if (!linkTo.includes('?') && !qString.includes(toQuery)) {
    toWithQueries = `${linkTo}?${queryStringified}`;
  } else {
    toWithQueries = `${linkTo.substring(0, linkTo.indexOf('?'))}?${queryStringified}`;
  }
  // End UTM forwarding logic

  return (
    <GatsbyLink
      to={queryStringified ? toWithQueries : withTrailingSlash}
      aria-label={ariaLabel || null}
      aria-describedby={ariaDescribedby || null}
      activeClassName={activeClassName}
      className={`${className || ''} ${withArrow ? styles.withArrow : ''}`}
      title={title || null}
      onClick={onLinkClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      tabIndex={tabIndex}
      role={role}
      style={style}
      id={id}
    >
      {children}
      {withArrow && <Arrow className="cta-arrow" />}
    </GatsbyLink>
  );
};

export default Link;
