import React from 'react';
import { ReactComponent as HeartSmiley } from 'images/heart-smiley-v4.svg';
import * as styles from './styles.module.scss';

const HeartSmily = () => (
  <div className={styles.container}>
    <HeartSmiley />
  </div>
);
export default HeartSmily;
