import React from 'react';
import { MarketoForm } from 'components';
import * as styles from './styles.module.scss';

const NewsletterSignup = () => (
  <div className={styles.newsletterSignup}>
    <div className={styles.leftCol}>
      <div>
        <h2>Stay in touch.</h2>
      </div>
    </div>
    <div className={`${styles.inlineForm} newsletter-footer-mkto-form`}>
      <p className="newsletter-footer-text">
        Don’t miss out on any of our exciting events and updates. Sign up for our newsletter to stay in the loop!
      </p>
      <MarketoForm formId="2272" />
    </div>
  </div>
);

export default NewsletterSignup;
