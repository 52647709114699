// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--ea660";
export var animateScrollOut = "styles-module--animateScrollOut--00c18";
export var colorSequence = "styles-module--colorSequence--e723d";
export var descContainer = "styles-module--descContainer--fbd2d";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--c1b5d";
export var grow = "styles-module--grow--df233";
export var growAndShrink = "styles-module--growAndShrink--de957";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--cfd5b";
export var icon = "styles-module--icon--a0a7e";
export var link = "styles-module--link--201c4";
export var moveBg = "styles-module--move-bg--0692b";
export var resourceLinks = "styles-module--resourceLinks--906e8";
export var rotateLoop = "styles-module--rotateLoop--997a4";
export var spin = "styles-module--spin--c9894";
export var spinCounter = "styles-module--spinCounter--db9d3";