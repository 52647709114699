// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--ab329";
export var animateScrollOut = "styles-module--animateScrollOut--c7583";
export var blockGroup = "styles-module--blockGroup--3c0ce";
export var colorSequence = "styles-module--colorSequence--bc603";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--e3b28";
export var grow = "styles-module--grow--8ce99";
export var growAndShrink = "styles-module--growAndShrink--84238";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--fa14d";
export var linkBlockTitle = "styles-module--linkBlockTitle--be02b";
export var linkGroup = "styles-module--linkGroup--07390";
export var moveBg = "styles-module--move-bg--de30c";
export var rotateLoop = "styles-module--rotateLoop--748e4";
export var spin = "styles-module--spin--34552";
export var spinCounter = "styles-module--spinCounter--d298d";