import React from 'react';
import { NewsletterSignup, FooterBottom, Link } from 'components';
import * as styles from './styles.module.scss';

const Footer = ({ footerLinks, socialLinks }) => {
  const footerNavCategories = ['Support', 'Why us', 'Learn', 'Company'];

  return (
    <footer className={styles.footer}>
      <NewsletterSignup />
      <div className={styles.footerMiddle}>
        <div className={styles.footerTagline}>
          <img
            src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/7b9797aa-981d-472e-9af7-fb80e3489460_icon-launchdarkly.svg"
            alt="LaunchDarkly iconki45 m"
          />
          <h3 className={`gradient-text ${styles.footerText}`}>
            We empower all teams to deliver and control their software.
          </h3>
        </div>
        <nav className={styles.footerNav}>
          {footerNavCategories.map((navCat) => (
            <div key={navCat}>
              <h6>{navCat}</h6>
              <ul>
                {footerLinks.map((footerLink) => {
                  const { category, link_text: linkText, link } = footerLink;
                  if (navCat === category) {
                    return (
                      <li key={linkText.text}>
                        <Link to={link.url}>{linkText.text}</Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          ))}
        </nav>
      </div>
      <FooterBottom data={socialLinks} />
    </footer>
  );
};

export default Footer;
