import React from 'react';
import * as styles from './styles.module.scss';

const GrowingHeardJson = () => (
  <div className={styles.growingHeart}>
    <img
      className={styles.heart}
      src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/597b6ee1-983c-4ff3-a2c7-39e927e162b7_pink-heart.svg"
      alt="json heart"
    />
    <img
      className={styles.jsonBubble}
      src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/abda5269-b0d2-4164-a6ce-a0e50a43f47c_json-bubble.svg"
      alt="json bubble"
    />
  </div>
);

export default GrowingHeardJson;
