import React from 'react';
import { Section, Link, Image } from 'components';
import * as styles from './styles.module.scss';

const MultiLinkBlocks = ({ data }) => (
  <Section whiteBg>
    <div className={styles.blockGroup}>
      {data.map((block) => {
        const {
          primary: {
            title1: title,
            description,
            cta_text: ctaText,
            cta_link: ctaLink,
            block_title: blockTitle,
            block_icon: blockIcon,
          },
          items,
        } = block;

        const titleText = title?.text;
        const descHtml = description?.html;
        const descText = description?.text;
        const ctaTextStr = ctaText?.text;
        const ctaUrl = ctaLink?.url;
        const blockTitleText = blockTitle?.text;

        return (
          <div>
            {titleText && <h3>{titleText}</h3>}
            {descText && <div dangerouslySetInnerHTML={{ __html: descHtml }} />}
            {ctaTextStr && (
              <Link to={ctaUrl} withArrow>
                {ctaTextStr}
              </Link>
            )}
            {blockTitleText && (
              <>
                <div className={styles.linkBlockTitle}>
                  <img src={blockIcon.url} alt={`${blockTitleText} icon`} />
                  <h5>{blockTitleText}</h5>
                </div>
                <ul className={styles.linkGroup}>
                  {items.map((linkGroup) => {
                    const { link_title: linkTitle, link_url: linkUrl } = linkGroup;
                    return (
                      <li>
                        <Link to={linkUrl.url}>{linkTitle.text}</Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
        );
      })}
    </div>
  </Section>
);

export default MultiLinkBlocks;
