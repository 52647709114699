import React from 'react';
import { DocumentationNav, ResourcesNav } from 'components';
import * as styles from './styles.module.scss';

const Navigation = ({ linkItem, resourceLinkItem }) => {
  const handleMouseEnter = (e) => {
    if (window.innerWidth > 1024) {
      const el = e.currentTarget;
      el.classList.add('nav-open');
      setTimeout(() => {
        el.querySelector('.nav-item-container').classList.add('animate');
      }, 10);
    }
  };

  const handleMouseLeave = (e) => {
    if (window.innerWidth > 1024) {
      e.currentTarget.classList.remove('nav-open');
      e.currentTarget.querySelector('.nav-item-container').classList.remove('animate');
    }
  };

  const handleMouseClick = (e) => {
    if (window.innerWidth <= 1024) {
      const el = e.currentTarget;
      if (el.className.indexOf('nav-open') >= 0) {
        el.classList.remove('nav-open');
        el.querySelector('.nav-item-container').classList.remove('animate');
      } else {
        el.classList.add('nav-open');
        setTimeout(() => {
          el.querySelector('.nav-item-container').classList.add('animate');
        }, 10);
      }
    }
  };

  return (
    <nav className={styles.nav}>
      <div
        onClick={handleMouseClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={styles.navItems}
      >
        <span>
          <span>Documentation</span>
          <span className={styles.navArrow} />
        </span>
        <div className={styles.navItem}>
          <div className={`${styles.navItemContainer} nav-item-container`}>
            <DocumentationNav nav={linkItem} />
          </div>
        </div>
      </div>

      <div
        onClick={handleMouseClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={styles.navItems}
      >
        <span>
          <span>Resources</span>
          <span className={styles.navArrow} />
        </span>
        <div className={styles.navItem}>
          <div className={`${styles.navItemContainer} nav-item-container`}>
            <ResourcesNav nav={resourceLinkItem} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
