import React from 'react';
import { Header, Footer } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import * as styles from './styles.module.scss';
import '../../../sass/global/styles.scss';

const Layout = (props) => {
  const { children, mainClassName } = props;
  const query = graphql`
    query FooterNavHomepageQuery {
      prismicSettings {
        data {
          resources_link_item {
            link_icon
            link_text {
              text
            }
            link_description {
              text
              html
            }
            link {
              url
            }
          }
          link_item {
            link_text {
              text
            }
            nav_group
            nav_category
            link {
              url
            }
          }
          footer_links {
            category
            link_text {
              text
            }
            link {
              url
            }
          }
          social_links {
            link_text {
              text
            }
            link {
              url
            }
            social_icon {
              url
              localFile {
                url
              }
            }
          }
        }
      }
    }
  `;
  const queriedData = useStaticQuery(query);

  const {
    prismicSettings: {
      data: {
        link_item: linkItem,
        resources_link_item: resourceLinkItem,
        footer_links: footerLinks,
        social_links: socialLinks,
      },
    },
  } = queriedData;

  return (
    <>
      <Header linkItem={linkItem} resourceLinkItem={resourceLinkItem} />
      <main className={`${mainClassName || ''} ${styles.main}`}>{children}</main>
      <Footer footerLinks={footerLinks} socialLinks={socialLinks} />
    </>
  );
};

export default Layout;
