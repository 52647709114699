import React from 'react';
import * as styles from './styles.module.scss';

const ConnectToggle = () => (
  <div className={styles.toggleContainer}>
    <div className={styles.toggleBall} />
  </div>
);

export default ConnectToggle;
