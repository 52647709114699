// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--7aac6";
export var animateScrollOut = "styles-module--animateScrollOut--fe58b";
export var colorSequence = "styles-module--colorSequence--5a7e5";
export var container = "styles-module--container--2a706";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--92a4a";
export var eyeAnimation = "styles-module--eyeAnimation--15e60";
export var grow = "styles-module--grow--36990";
export var growAndShrink = "styles-module--growAndShrink--8dc3f";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--637ae";
export var moveBg = "styles-module--move-bg--8d243";
export var rotateLoop = "styles-module--rotateLoop--14e60";
export var spin = "styles-module--spin--cfbde";
export var spinCounter = "styles-module--spinCounter--96246";