// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--efbf0";
export var animateScrollOut = "styles-module--animateScrollOut--185b7";
export var colorSequence = "styles-module--colorSequence--83cbc";
export var description = "styles-module--description--896f4";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--732e6";
export var grow = "styles-module--grow--df7b3";
export var growAndShrink = "styles-module--growAndShrink--29cba";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--b7578";
export var imageContainer = "styles-module--imageContainer--d739d";
export var moveBg = "styles-module--move-bg--22991";
export var playVideoIcon = "styles-module--playVideoIcon--0904b";
export var previewImage = "styles-module--previewImage--ab723";
export var rotateLoop = "styles-module--rotateLoop--76681";
export var spin = "styles-module--spin--a9409";
export var spinCounter = "styles-module--spinCounter--cb530";
export var video = "styles-module--video--464b4";
export var videoLength = "styles-module--videoLength--0d11b";
export var videoTitle = "styles-module--videoTitle--1790e";
export var watch = "styles-module--watch--9fd69";