import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const LangInteCard = ({ title, icon, url }) => (
  <Link className={styles.card} to={url}>
    <img src={icon} alt={`${title} logo`} />
    <p>{title}</p>
  </Link>
);

export default LangInteCard;
