import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Facebook from './Facebook';
import Twitter from './Twitter';
import useDarkMode from '../../../hooks/useDarkMode';

export const Head = ({ title, desc, banner, schema, pathname, article, node, noTitlePipe }) => {
  const { site } = useStaticQuery(query);
  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      siteName,
      siteLanguage,
      ogLanguage,
      author,
      twitter,
    },
  } = site;

  const darkMode = useDarkMode();

  useEffect(() => {
    const favicon = document.querySelectorAll('[rel="icon"]')[0];
    if (darkMode) {
      if (favicon) favicon.href = '/favicon-dark-mode.png';
    } else if (favicon) favicon.href = '/favicon-light-mode.png';
  }, [darkMode]);

  const ogImage = banner || `${siteUrl}${defaultBanner}`;

  const siteTitle = title ? `${title}${noTitlePipe ? '' : ' | LaunchDarkly'}` : defaultTitle;

  const seo = {
    title: siteTitle,
    description: desc || defaultDescription,
    image: ogImage,
    url: `${siteUrl}${pathname || ''}`,
  };

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: seo.url,
    headline: seo.title,
    inLanguage: siteLanguage,
    mainEntityOfPage: seo.url,
    description: seo.description,
    name: seo.title,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2021',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: ogImage,
    },
  };

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
  };

  return (
    <>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={siteName}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
      {/*<FontPreload /> */}
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="gatsby-starter" content="Gatsby Starter Prismic" />
      {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
      {!article && <script type="application/ld+json">{JSON.stringify(schema || schemaOrgWebPage)}</script>}
      {article && <script type="application/ld+json">{JSON.stringify(schema || schemaArticle)}</script>}
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      <link rel="icon" type="image/png" href="/favicon-light-mode.png" />
    </>
  );
  // return (
  //   <>
  //     <title>wtf</title>
  //     <meta name="description" content="Hello World" />
  //   </>
  // );
};

// export default SEO;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        siteUrl
        siteName
        siteLanguage
        ogLanguage
        author
        twitter
      }
    }
  }
`;