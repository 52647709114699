import React from 'react';
import * as styles from './styles.module.scss';

const SlidingArrow = () => (
  <div className={styles.slidingArrow}>
    <div className={styles.arrowContainer}>
      <div className={styles.arrowBody} />
      <img
        className={styles.arrowHead}
        src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/c4ad8a9f-6820-4172-95ee-9d06aa3bd632_arrow-head.svg"
        alt="arrow head"
      />
    </div>
    <img
      className={styles.flameBubble}
      src="https://developers-launchdarkly.cdn.prismic.io/developers-launchdarkly/3b940c7a-ef67-4051-a552-ed48904ab1f0_flame-bubble.svg"
      alt="Flame bubble"
    />
  </div>
);

export default SlidingArrow;
