// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--cbc74";
export var animateScrollOut = "styles-module--animateScrollOut--07287";
export var card = "styles-module--card--7bffe";
export var colorSequence = "styles-module--colorSequence--4b30b";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--fddd7";
export var grow = "styles-module--grow--d49e2";
export var growAndShrink = "styles-module--growAndShrink--d3b51";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--5c837";
export var moveBg = "styles-module--move-bg--1fcb4";
export var rotateLoop = "styles-module--rotateLoop--b3064";
export var spin = "styles-module--spin--830d2";
export var spinCounter = "styles-module--spinCounter--aa38c";