// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--3dff5";
export var animateScrollOut = "styles-module--animateScrollOut--f1e2f";
export var colorSequence = "styles-module--colorSequence--809e5";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--05baa";
export var grow = "styles-module--grow--dbc59";
export var growAndShrink = "styles-module--growAndShrink--e3682";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--3e813";
export var moveBg = "styles-module--move-bg--18b26";
export var nav = "styles-module--nav--f6daf";
export var navArrow = "styles-module--navArrow--16010";
export var navItem = "styles-module--navItem--53a85";
export var navItemContainer = "styles-module--navItemContainer--ef095";
export var navItems = "styles-module--navItems--90e08";
export var rotateLoop = "styles-module--rotateLoop--f5334";
export var spin = "styles-module--spin--f7229";
export var spinCounter = "styles-module--spinCounter--9801c";