// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--8def3";
export var animateScrollOut = "styles-module--animateScrollOut--ff39a";
export var burgerOpen = "styles-module--burgerOpen--e904b";
export var colorSequence = "styles-module--colorSequence--8806c";
export var demoButton = "styles-module--demoButton--274f7";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--28fb6";
export var grow = "styles-module--grow--cbd10";
export var growAndShrink = "styles-module--growAndShrink--59ce3";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--cd3db";
export var header = "styles-module--header--6fe10";
export var logo = "styles-module--logo--66b0a";
export var mobileBurger = "styles-module--mobileBurger--edd0f";
export var mobileMenuOpen = "styles-module--mobileMenuOpen--631ee";
export var moveBg = "styles-module--move-bg--a976a";
export var navLeft = "styles-module--navLeft--1f65f";
export var navRight = "styles-module--navRight--3f68f";
export var rotateLoop = "styles-module--rotateLoop--c0404";
export var signInButton = "styles-module--signInButton--fb584";
export var signInGroup = "styles-module--signInGroup--71e2e";
export var spin = "styles-module--spin--265f1";
export var spinCounter = "styles-module--spinCounter--ec152";