// extracted by mini-css-extract-plugin
export var activeTab = "styles-module--activeTab--30164";
export var animateScrollIn = "styles-module--animateScrollIn--544e1";
export var animateScrollOut = "styles-module--animateScrollOut--468a3";
export var cardContainer = "styles-module--cardContainer--a397d";
export var cardContainerInner = "styles-module--cardContainerInner--f1b9b";
export var colorSequence = "styles-module--colorSequence--66c91";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--f3445";
export var grow = "styles-module--grow--ea9c0";
export var growAndShrink = "styles-module--growAndShrink--adfd2";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--5920b";
export var moveBg = "styles-module--move-bg--0f7ec";
export var rotateLoop = "styles-module--rotateLoop--0305b";
export var section = "styles-module--section--3b35d";
export var spin = "styles-module--spin--114bc";
export var spinCounter = "styles-module--spinCounter--1abc4";
export var tabs = "styles-module--tabs--a7b41";