import React, { useState } from 'react';
import { Section, LangInteCard } from 'components';
import * as styles from './styles.module.scss';

const LangHorizontalTab = ({ title, links }) => {
  const [currentTab, setCurrentTab] = useState('all');
  const tabs = [
    {
      url: 'all',
      text: 'All',
    },
    {
      url: 'server-side',
      text: 'Server-side',
    },
    {
      url: 'client-side',
      text: 'Client-side',
    },
  ];

  const tabToggle = (e) => {
    e.preventDefault();
    setCurrentTab(e.target.hash.split('#')[1]);
  };

  return (
    <div>
      <Section className={styles.section} whiteBg>
        <h2>{title}</h2>
        <div className={styles.tabs}>
          <nav>
            <ul>
              {tabs.map((tab) => {
                const activeTabClass = tab.url === currentTab ? styles.activeTab : '';
                return (
                  <li>
                    <a className={activeTabClass} onClick={tabToggle} href={`#${tab.url}`}>
                      {tab.text}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </Section>
      <div className={styles.cardContainer}>
        <div className={`${styles.cardContainerInner} container`}>
          {links.map((linkItem) => {
            const { block_title: blockTitle, link_url: linkUrl, logo, server_or_client: serverClient } = linkItem;

            const normalizedServerClient = serverClient.toLowerCase();
            if (currentTab === 'all' || currentTab === normalizedServerClient) {
              return <LangInteCard key={blockTitle.text} title={blockTitle.text} url={linkUrl.url} icon={logo.url} />;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default LangHorizontalTab;
